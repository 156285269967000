@import url('https://fonts.googleapis.com/css2?family=Grechen+Fuemen&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

:root {
  --font-color: #000000;
  --primary-color: rgb(252, 215, 52);
  --primary-color-light: rgb(255, 255, 107);
  --primary-color-dark: rgb(197, 166, 0);
  --box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  --box-shadow-dense: rgba(0, 0, 0, 0.616) 0px 5px 15px;
  --border-radius: 0.4rem;
  --text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  --border-bottom: 0.3rem solid var(--primary-color-dark);
  --black: rgb(0, 0, 0);
  --white: rgb(255, 255, 255);
  --margin-10: 1rem;
  --margin-15: 1.5rem;
  --margin-20: 2rem;
  --margin-25: 2.5rem;
  --margin-30: 3rem;
  --margin-section: 5rem;
  --padding-10: 1rem;
  --padding-15: 1.5rem;
  --padding-20: 2rem;
  --padding-25: 2.5rem;
  --padding-30: 3rem;
  --container-width: 86%;
  --container-width-sm: 94%;
}

html {
  font-size: 10px;
}

.wrapper {
  margin: 5rem;
  box-sizing: border-box;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
}

@media screen and (max-width: 700px) {
  .wrapper {
    margin: 2rem;
  }
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  text-shadow: var(--text-shadow);
}

h1 {
  font-family: 'Grechen Fuemen', cursive;
  font-size: 3.2rem;
  letter-spacing: 0.2rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 1.872rem;
}

h4,
p,
li {
  font-size: 1.6rem;
}

h5 {
  font-size: 1.328rem;
}

h6 {
  font-size: 1.072rem;
}

ul {
  padding: 0;
  list-style: none;
}

@media screen and (max-width: 700px) {
  html {
    font-size: 6px;
  }
}

/*  */
/* BUTTON  */
/*  */
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6rem 0 0 0;
}

.btn > a {
  background-color: var(--primary-color-dark);
  box-shadow: var(--box-shadow);
  padding: 1rem 2rem;
  border-radius: var(--border-radius);
  color: var(--black);
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.btn > a:hover {
  box-shadow: var(--box-shadow-dense);
  transform: scale(1.03);
}

/*  */
/* HEADER  */
/*  */

.header {
  padding: 0 var(--padding-15);
}

.header {
  display: flex;
  justify-content: space-between;
  background-color: var(--primary-color-dark);
  align-items: center;
}

.logo {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo > img {
  height: 5rem;
}

/* NAVIGATION MENU */

.nav-lists {
  display: flex;
  justify-content: space-evenly;
}

.nav-list {
  list-style-type: none;
}

.nav-list:not(last-child) {
  padding: 0.5rem 1rem;
}

.nav-list:last-child {
  padding: 0.5rem 0 0.5rem 1rem;
}

.nav-link {
  text-decoration: none;
  color: var(--black);
  text-transform: capitalize;
  font-weight: 500;
}

.nav__holder {
  display: none;
}

.nav--mob__input {
  display: none;
}

.nav--mob {
  display: none;
}

@media screen and (max-width: 600px) {
  .header {
    min-height: 10rem;
  }

  .nav {
    display: none;
  }

  .nav--mob {
    display: block;
    margin: 2rem 0;
  }

  .nav--mob__label {
    height: 7rem;
    width: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: var(--box-shadow);
    z-index: 19999;
    position: absolute;
    top: 3.5rem;
    right: 6rem;
  }

  .hamburger-bar {
    width: 3rem;
    height: 0.4rem;
    display: inline-block;
    background-color: #000;
    position: relative;
    border-radius: var(--border-radius);
  }

  .hamburger-bar::after,
  .hamburger-bar::before {
    content: '';
    display: inline-block;
    background-color: #000;
    width: 3rem;
    height: 0.4rem;
    position: absolute;
    border-radius: var(--border-radius);
    transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .hamburger-bar::after {
    bottom: 5px;
  }

  .hamburger-bar::before {
    top: 5px;
  }

  .nav--mob__input:checked + .nav--mob__label > .hamburger-bar {
    background-color: white;
  }

  .nav--mob__input:checked + .nav--mob__label > .hamburger-bar::before {
    transform: rotate(-45deg);
    top: 0;
  }

  .nav--mob__input:checked + .nav--mob__label > .hamburger-bar::after {
    transform: rotate(-135deg);
    bottom: 0;
  }

  .nav__holder {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }

  .nav__background {
    position: absolute;
    top: 3.5rem;
    right: 6rem;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    background: radial-gradient(
      var(--primary-color-light),
      var(--primary-color-dark)
    );
    z-index: 999;
    transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .nav--mob__lists {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -99;
    opacity: 0;
    transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .nav--mob__input:checked ~ .nav__holder > .nav__background {
    transform: scale(50);
  }

  .nav--mob__input:checked ~ .nav__holder > .nav--mob__lists {
    z-index: 9991;
    opacity: 1;
  }

  .nav--mob__input:checked ~ .nav__holder > .nav--mob__lists > .nav--mob__list {
    transform: translateX(0);
  }

  .nav--mob__list {
    font-size: 3rem;
    text-align: center;
    line-height: 5rem;
    transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  .nav--mob__list:nth-child(2n) {
    transform: translateX(25vw);
  }

  .nav--mob__list:nth-child(2n + 1) {
    transform: translateX(-25vw);
  }
}

/*  */
/* HERO */
/*  */

.hero-container {
  background-image: url(../src/assets/pattern-randomized.svg);
  height: 75vh;
  display: flex;
  justify-content: space-between;
  padding: 0 10rem;
}

.hero-img {
  width: 50%;
}

.hero-caption {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  justify-self: flex-end;
}

.hero-title {
  margin: 0;
  font-size: 5rem;
}

.hero-subtitle {
  font-size: 4rem;
  margin: 0;
  font-weight: 600;
  margin-top: var(--margin-30);
  display: flex;
  align-items: center;
  justify-content: center;
}

.subtitle {
  border-bottom: var(--border-bottom);
}

.icon {
  height: 4rem;
  margin: 1rem;
}

.icon--earth {
  border-bottom: none;
  animation: earthSpin 4s linear 4s infinite;
  fill: var(--color-primary-dark);
}

.hero-img {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.hero-img > img {
  height: 90%;
}

@keyframes earthSpin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 700px) {
  .hero-container {
    flex-direction: column-reverse;
    padding: 0 5rem;
    height: auto;
  }

  .hero-img {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 5rem 0;
  }

  .hero-img > img {
    width: 80%;
    height: auto;
  }
}

/* headings */
.section-heading {
  text-transform: uppercase;
  text-align: center;
  position: relative;
  margin: 6rem 0;
}

.section-heading::after {
  content: '';
  display: inline-block;
  width: 10rem;
  text-align: center;
  border-bottom: var(--border-bottom);
  position: absolute;
  bottom: -10%;
  right: 50%;
  transform: translateX(50%);
}

/*  */
/*  */
/* SERVICES */

.services-container {
  width: var(--container-width);
  margin: 10rem auto;
}

.card-holder {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.service-title {
  margin-left: 1.5rem;
  margin-bottom: 0.5rem;
}

.card {
  background-color: var(--primary-color-light);
  color: var(--black);
  width: 30.5%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 9px 18px, rgba(0, 0, 0, 0.22) 0px 7px 6px;
  border-radius: var(--border-radius);
  overflow: hidden;
  transition: all 0.5s ease;
  background-position: center;
}

.card-title {
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  margin-top: 0;
  text-align: center;
}

.card-lists {
  text-align: justify;
  padding: 1rem 2rem 1rem 1rem;
  letter-spacing: 1px;
  word-spacing: 3px;
  line-height: 2rem;
}

.card-list {
  display: flex;
}

.card-list:not(:last-child) {
  margin-bottom: 0.75rem;
}

.card-list > span:first-child {
  padding-right: 0.5rem;
}

.card:hover {
  transform: scale(1.1);
  backface-visibility: hidden;
  perspective: 1000px;
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .card-holder {
    flex-direction: column;
  }

  .card {
    width: 85%;
    margin: 3rem auto;
  }
}

/*  */
/* PROJECTS */
/*  */
.projects-container {
  width: var(--container-width);
  margin: 5rem auto 10rem auto;
}

.project-details--width {
  width: 48%;
}

.project {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 1.5rem 0;
}

.project:not(:last-child) {
  border-bottom: 1px solid var(--primary-color-light);
}

.project-title {
  margin: 0.75rem 0;
}

.project-duration {
  font-weight: 500;
  margin: 0;
}

.project-description {
  text-align: justify;
  letter-spacing: 1px;
  word-spacing: 3px;
  line-height: 2.6rem;
}

.project-image {
  overflow: hidden;
  width: 48%;
  border-radius: var(--border-radius);
  max-height: 45rem;
}

.project-image > img {
  background-size: cover;
  width: 100%;
  height: 45rem;
}

@media screen and (max-width: 700px) {
  .project {
    flex-direction: column-reverse;
  }

  .project-image,
  .project-details--width {
    width: 100%;
  }
}

/*  */
/* FOOTER */
/*  */
.footer {
  background-color: var(--primary-color-dark);
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
}

.footer > p {
  margin: 0;
}

.footer > p:not(:last-child) {
  margin-bottom: 1rem;
}

.designer {
  color: var(--black);
  display: inline-block;
  transition: all 1s ease;
  font-weight: 600;
}

.designer:hover {
  transform: scale(1.09) rotate(-3deg) translateX(10px);
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

/*  */
/* SERVICE DETAILS */
/*  */
.service-screen {
  margin-bottom: 10rem;
}

.service-details-container {
  width: var(--container-width);
  margin: 5rem auto;
  position: relative;
}

.service-image-container,
.service-details {
  width: 55%;
  border-radius: var(--border-radius);
}

.service-image-container {
  background-color: var(--primary-color-light);
  opacity: 0.4;
  min-height: 40rem;
  max-height: 45rem;
  overflow: hidden;
  margin: 0;
  transition: all 1s ease-out;
}

.service-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  margin: 0;
  transition: all 1s ease-out;
}

.service-details-container:hover > .service-image-container {
  opacity: 1;
}

.service-details-container:hover > .service-image-container > .service-image {
  transform: scale(1.1);
}

.service-details {
  background-color: rgba(255, 255, 107, 0.85);
  position: absolute;
  top: 50%;
  right: 0;
  max-height: 38rem;
  transform: translateY(-50%);
  padding: 3rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
}

.service-description {
  text-align: justify;
  line-height: 2rem;
}

@media screen and (max-width: 700px) {
  .service-details-container {
    margin-bottom: -15rem;
  }

  .service-details {
    position: static;
    margin-top: 5rem;
  }

  .service-image-container,
  .service-details {
    width: 100%;
    border-radius: var(--border-radius);
    height: 30rem;
  }
}

/*  */
/* CONTACT SCREEN */
/*  */

.contact-container {
  display: flex;
  width: var(--container-width);
  margin: 0 auto;
  padding: 5rem 0;

  background-image: url(../src/assets/pattern-randomized.svg);
}

.contact-detail-holder {
  width: 65%;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
}

.contact-detail-holder > p {
  display: flex;
  align-items: center;
}

.contact-detail-holder > p > strong {
  display: flex;
  align-items: center;
  min-width: 15rem;
}

.contact-detail-holder > p > a {
  color: var(--black);
  text-decoration: none;
  transition: all 0.2s linear;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.contact-detail-holder > p > a:hover {
  transform: scale(1.08) rotateX(5deg);
  font-weight: 600;
}

.contact-icon {
  font-size: 2.5rem;
  margin-right: 1rem;
}

.icon--mail {
  color: #bb001b;
}

.icon--phone {
  color: #333;
}

.icon--whatsapp {
  color: #075e54;
}

.icon--viber {
  color: #59267c;
}

.icon--linkedin {
  color: #0077b5;
}

.image-holder {
  width: 35%;
  min-height: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.image-background {
  width: 28rem;
  height: 28rem;
  position: absolute;

  background-image: url(../src/assets/earth.png);
  background-repeat: no-repeat;
  background-position: center center;

  animation: earthSpin 4s linear 4s infinite;
  z-index: 1;
}

.image {
  width: 25rem;
  height: 25rem;

  z-index: 2;
}

.contact-image {
  width: 100%;
}

@media screen and (max-width: 700px) {
  .contact-container {
    flex-direction: column-reverse;
    width: var(--container-width-sm);
  }

  .image-holder {
    margin: 0 auto;
  }

  .image-background {
    background-image: url(../src/assets/earthsm.png);
  }

  .contact-image {
    height: 100%;
    width: auto;
  }

  .contact-detail-holder {
    padding: 0 3rem;
  }

  .contact-detail-holder > p > strong {
    min-width: 12rem;
  }
}

/*  */
/* ABOUT */
/*  */
.about-container {
  width: var(--container-width);
  margin: 5rem auto 8rem auto;
}

.heading--bg {
  background-color: var(--primary-color-dark);
  padding: 1rem;
}

.personal-info-container,
.education-info-container,
.training-skills-container,
.professional-social-activities-container {
  padding: 2rem 0;
  border-bottom: 1px solid var(--primary-color-dark);
}

.personal-info {
  display: flex;
}

.personal-info > strong {
  min-width: 15rem;
  display: inline-block;
}

.personal-info > a {
  text-decoration: none;
  color: var(--black);
}

.education-container,
.computer-container {
  padding-bottom: 1rem;
}

.education-container > h3,
.computer-container > h3 {
  color: var(--primary-color-dark);
  text-transform: uppercase;
}

.education-container:not(:last-child),
.computer-container:not(:last-child) {
  border-bottom: 1px solid rgba(51, 51, 51, 0.116);
}

.skills-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.skill {
  background-color: var(--primary-color-dark);
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  width: max-content;
}

.skill:not(:last-child) {
  margin-bottom: 0.25rem;
}

.other-activities-list {
  margin: 1.5rem 0;
  padding: 0;
  line-height: 2.5rem;
  text-align: justify;
  letter-spacing: 1px;
  display: flex;
}

.other-activities {
  margin: 0 1rem;
}

.other-activities-list > span {
  margin-right: 1rem;
  font-weight: 900;
}

@media screen and (max-width: 700px) {
  .about-container {
    margin-top: -5rem;
  }
}
